<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Products',

    metaInfo: {
      title: 'Products',
      titleTemplate: 'Howellcare Industries Sdn Bhd Products',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'EXAMINATION GLOVES, FOOD CONTACT GLOVES, CHEMOTHERAPY GLOVES, PPE WITH CE CATEGORY III GLOVES, PRODUCT SPECIFICATION, NON-STERILE, POWDER FREE, AMBIDEXTROUS, FINGER & PALM TEXTURED, BEADED CUFF, ONLINE CHLORINATED, nitrile, glove, manufacturing, products, international' },
        { vmid: 'description', name: 'description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'products-main',
        'products-spec',
        'nitrile-gloves',
        'latex-gloves',
        'before-footer',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'products',
      },
    },
  }
</script>
